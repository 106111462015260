<template>
  <v-container
    v-if="company"
    class="layout-container"
  >
    <BaseHeader
      title="MultiScale Vereinbarung zur Auftragsverarbeitung (AVV)"
      :style-variant="10"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Die MultiScale AG verarbeitet in Ihrem Auftrag personenbezogene Daten. Ein aktueller AVV dient Ihnen als Sicherheit, dass die
          Datenverarbeitung ordnungsgemäß und DSGVO-konform erfolgt.
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-card
      v-if="!hasSeperateAvv"
      outlined
      class="pa-8"
    >
      <p>
        Die MultiScale Vereinbarung zur Auftragsverarbeitung ist als Anlage 2 in den
        <router-link
          class="text-decoration-none"
          to="/license-agreement"
        >
          <span class="text-decoration-underline">MultiScale Nutzungs- und Lizenzbedingungen</span>
        </router-link>
        hinterlegt
      </p>
    </v-card>

    <div v-else>
      <v-card
        v-if="isContractOwner"
        outlined
        style="overflow:hidden"
      >
        <v-row>
          <v-col
            v-if="$vuetify.breakpoint.smAndUp"
            sm="3"
          >
            <div class="design-wrapper">
              <Leaf
                class="design-elements"
                width="1000"
                style="top:-1200px; right: 100px; opacity: 0.2"
                fill
              />

              <Leaf
                class="design-elements"
                width="40"
                style="top:10px; right: 150px; opacity: 0.8"
                stroke
                :stroke-width="1"
              />

              <Leaf
                class="design-elements"
                width="40"
                style="top:10px; right:140px; opacity: 0.3"
                fill
              />

              <SingleCircle
                class="design-elements"
                width="20px"
                style="top: 90px; right: 190px; opacity: 0.8"
                stroke
              />

              <SingleCircle
                class="design-elements"
                width="20px"
                style="top: 90px; right: 185px; opacity: 0.3"
                fill
              />
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="pa-6"
          >
            <v-alert
              v-if="showUpdateWarning"
              type="warning"
            >
              Update: Es gab ein Update in der Vereinbarung zur Auftragsverarbeitung. Bitte lesen Sie die neue Vereinbarung und akzeptieren Sie diese, um die App weiter zu nutzen.
            </v-alert>

            <div class="d-flex align-center">
              <v-icon class="mr-2">
                mdi-file
              </v-icon>
              <a
                :href="avvUrl"
                target="_blank"
                class="text-decoration-none"
              >
                <h4 class="pb-0 mb-0 ">
                  MultiScale Vereinbarung zur Auftragsverarbeitung (AVV)
                  <v-icon
                    color="primary"
                    size="14"
                  >
                    mdi-open-in-new
                  </v-icon>
                </h4>
              </a>
            </div>

            <div
              v-if="showDefaultText"
              class="pt-2"
            >
              <span>
                Bitte lesen Sie die MultiScale Vereinbarung zur Auftragsverarbeitung.
                Nach Ihrer Zustimmung können Sie die App sofort nutzen.
              </span>
            </div>

            <div class="pt-2">
              <v-checkbox
                v-model="isAccept"
                :disabled="!canAccept"
                label="Ich habe die Vereinbarung zur Auftragsverarbeitung gelesen und akzeptiere diese."
              />
            </div>

            <div
              v-if="canAccept"
              class="justify-end d-flex"
            >
              <v-btn
                class="primary"
                :disabled="!isAccept"
                @click="submit"
              >
                Speichern und weiter
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        v-else
        outlined
        class="pa-8"
      >
        <div v-if="!isAccept">
          <p>
            Die Vereinbarung zur Auftragsverarbeitung wurde von dem Vertragsinhaber Ihres Unternehmens <b class="red--text">noch nicht akzeptiert</b>.
            Als Auftraggeber sind Sie im Sinne der DSGVO dazu verpflichtet. Die Akzeptanz des AVV ist die Voraussetzung für die Nutzung der
            {{ readablePlatformName }} App. Bitte wenden Sie sich an Ihren Manager.
          </p>
          <p><b>Das können Sie tun:</b> Erinnern Sie den Vertragsinhaber Ihres Unternehmens an die Akzeptanz der Vereinbarung zur Auftragsverarbeitung.</p>
        </div>
        <div v-else>
          <p>Die Vereinbarung zur Auftragsverarbeitung (AVV) wurde von dem Vertragsinhaber Ihres Unternehmens <b class="green--text">akzeptiert</b>.</p>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AVV from '@/queries/Avv.gql'
import UPDATE_AVV from '@/queries/UpdateAvv.gql'
import featureMixin from '@/mixins/feature'
import HeaderDesign from '@/modules/licenseAgreement/HeaderDesign.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { BaseHeader, Leaf, SingleCircle, HeaderDesign },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      isAccept: false,
      canAccept: true
    }
  },
  computed: {
    isContractOwner () {
      return this.$auth.user.isContractOwner
    },
    avvUrl () {
      return this.getFeature(this.featureNames.AVV).config.avvUrl
    },
    showDefaultText () {
      return !this.company.avv?.acceptedByUserId && !this.company.avv?.acceptedAt
    },
    showUpdateWarning () {
      return this.company.avv?.acceptedAt && this.updatedAvvExists
    },
    updatedAvvExists () {
      if (this.company.avv?.acceptedByUserId && !this.company.avv?.acceptedAt) return false
      const lastModifiedAt = this.getFeature(this.featureNames.AVV).config?.lastModifiedAt
      if (!lastModifiedAt) return false
      return new Date(this.company.avv?.acceptedAt).getTime() < new Date(lastModifiedAt).getTime()
    },
    hasSeperateAvv () {
      const licenseAgreementFeature = this.getFeature(this.featureNames.LICENSE_AGREEMENT)
      return !(licenseAgreementFeature && licenseAgreementFeature.isActive && licenseAgreementFeature.config.hasAvv)
    },
    breadcrumbItems () {
      return [
        { text: 'Impressum & Rechtliches', to: { path: '/legal' }, disabled: false, exact: true },
        { text: 'Vereinbarung zur Auftragsverarbeitung' }
      ]
    }
  },
  methods: {
    async submit () {
      try {
        const { data: { updateAvv } } = await this.$apollo.mutate({
          mutation: UPDATE_AVV,
          variables: {
            companyId: this.$auth.user.companyId
          },
          update: (store, { data: { updateAvv } }) => {
            const data = store.readQuery({ query: AVV, variables: { id: this.$auth.user.companyId } })
            data.company.avv.acceptedAt = updateAvv
            store.writeQuery({ query: AVV, data, variables: { id: this.$auth.user.companyId } })
          }
        })

        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Vereinbarung zur Auftragsverarbeitung akzeptiert' })

        this.updateAcceptUI(updateAvv)
        this.$router.push('/dashboard')
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Akzeptieren' })
      }
    },

    updateAcceptUI (avvAcceptedByUserId) {
      this.isAccept = !!(avvAcceptedByUserId && !this.updatedAvvExists)
      this.canAccept = !this.isAccept
    }
  },
  apollo: {
    company: {
      query: AVV,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      result ({ data }) {
        if (!data) return
        this.updateAcceptUI(data.company.avv.acceptedByUserId)
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      }
    }
  }
}
</script>
<style scoped>
.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
</style>
